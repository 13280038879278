import React from "react"
import Layout from "../components/layout"
import "../styles/terms.scss"
export default function Terms() {
  return (
    <Layout>
        <div className="terms">
            <h1>Användarvillkor för Iskolls app</h1>
            <p className="date">Senast uppdaterad 2022-12-01</p>

            <h2>Allmänt</h2>
            <p>Dessa användarvillkor (”Villkor”) är tillämpliga mellan dig som användare och leverantören av Iskoll, Papereed AB, org.nr 559020-3864, (Iskoll, ”vi”, ”oss”) när du använder Iskolls applikation. (Gäller både webbaserade och mobilt installerade versioner.)</p>

            <p>För att använda Iskolls applikation måste du vara minst 18 år och/eller i övrigt vara behörig att ingå avtal samt acceptera att bli bunden av Villkoren. Alternativt, för minderåriga, ha målsmans tillåtelse att använda applikationen.</p>

            <p>Din integritet är viktig. Iskoll behandlar dina personuppgifter i enlighet med tillämplig lagstiftning och all behandling omgärdas av lämpliga tekniska och organisatoriska skyddsåtgärder.</p>

            <h2>Registrering</h2>
            <p>Vid registrering skapar du ett konto som innehåller din e-postadress, namn och ett valbart användarnamn. E-postmeddelanden kan skickas via vår samarbetspartner Sendinblue för att bekräfta registrering och för att återställa lösenord.</p>

            <h2>Tjänsten</h2>
            <p>I iskollappen kan du publicera israpporter innehållande text, bild, video. Dessa rapporter blir synliga i appen för alla som använder den. Iskollappen gör det möjligt att se andra rapportörers israpporter.</p>

            <p>Tjänstens syfte är att tillhandahålla en mötesplats för fritidsaktiviteter på is genom att låta användare dela med sig av sina egna upplevelser och ta del av andras upplevelser. Allt delat innehåll och övriga aktiviteter i tjänsten ska ske i positiv ton och god anda.</p>
            
            <h2> Iskolls ansvar och rättigheter</h2>
            <p>Iskolls app tillhandahålls ”som den är” utan någon uttrycklig eller underförstådd garanti. Iskoll garanterar ingen specifik tillgänglighet, kvalitet eller lämplighet för ett visst syfte och ger inga garantier för att appen alltid är fri från fel eller avbrott. Din användning av Iskolls app sker uteslutande på egen risk. I den utsträckning som det är tillåtet enligt tvingande svensk rätt ansvarar Iskoll inte för dina eller tredje parts direkta, indirekta eller andra skador av något slag.</p>

            <p>Iskolls app utvecklas kontinuerligt och Iskolls app kan därför komma att ändras löpande så som Iskoll anser nödvändigt och/eller lämpligt. Ändringar kan till exempel innefatta nya funktioner eller ny behandling av dina personuppgifter. Iskoll förbehåller sig därför rätten att använda de personuppgifter som du lämnar i Iskolls app för detta syfte. Uppgifter aggregeras och/eller anonymiseras där så är lämpligt och möjligt. Vidare förbehåller Iskoll sig rätten att ändra eller upphöra med tillhandahållandet av Iskolls app så som Iskoll finner det lämpligt, oavsett anledning.</p>

            <p>Iskoll har rätt att ta bort israpporter och övrigt användardata om det innehåller stötande, felaktigt eller på annat sätt opassande innehåll. Bedömningen av vad som är opassande görs av Iskoll.</p>

            <p>Iskoll har rätt att stänga av användare som rapporterat stötande, felaktigt eller på annat sätt opassande innehåll. Iskoll har också rätt att stänga av användare som använder tjänsten på ett sätt som inte följer våra riktlinjer.</p>

            <h2> Användarens ansvar och rättigheter</h2>
            <p>Iskoll tillhandahåller Iskolls app till dig som slutanvändare. Under förutsättning att du följer Villkoren ger Iskoll dig en personlig, begränsad, uppsägningsbar, icke-exklusiv och icke-överlåtningsbar rätt och licens att ladda ner, installera och använda Iskolls app på din enhet. Din användning av Iskolls app regleras i dessa Villkor.</p>

            <p>Ditt användarkonto är personligt och dina kontouppgifter ska hållas hemliga och får inte delas med andra. Du ansvarar för all användning av Iskolls app som sker genom ditt konto. Om du misstänker att någon obehörig person använder ditt konto ska du omgående anmäla detta till Iskoll.</p>

            <p>
            Du ansvarar för att de uppgifter som har lämnats vid registrering är korrekta och att e postadressen är aktiv samt att det data och de inlägg du publicerar i Iskolls app inte är kränkande, nedsättande, skadligt, rasistiskt eller etniskt stötande, sexuellt explicit eller gör intrång i immateriell rättighet eller någons personliga integritet. Iskoll har rätt att ändra ditt användarnamn och radera dina inlägg/data om de, enligt Iskolls uppfattning, bryter mot Villkoren. Vidare ansvarar du för att ändra dina uppgifter hos Iskoll om tidigare lämnade uppgifter skulle bli inaktuella.
            </p>

            <p>Du kan närsomhelst välja att ta bort ditt konto vilket görs genom att kontakta Iskoll. Iskoll kommer då, i enlighet med gällande integritetspolicy, radera eller avidentifiera samtliga dina personuppgifter. Iskoll kan komma att spara information om dig, exempelvis om så krävs för uppfyllande av rättslig förpliktelse i enlighet med vår integritetspolicy.</p>

            <p>Dina rapporter tas inte automatiskt bort i samband med kontoavslut utan dessa tar du bort själv, direkt i Iskolls app, innan du kontaktar Iskoll för kontoavslut.</p>

            <h2>Immateriella rättigheter</h2> 
            <p>Alla immateriella rättigheter avseende Iskolls app tillhör Iskoll eller Iskolls licensgivare och samarbetspartners. Detta innefattar bland annat upphovsrätt, designrättigheter, varumärken, företagshemligheter, patent och know-how, oavsett om de förutsätter registrering eller inte.</p>

            <p>Användaren innehar de immateriella rättigheterna till sitt eget uppladdade bild och videomaterial. Användaren ger Iskoll den begränsade rätten att använda materialet i Iskolls app, på Iskolls webbsite, i Iskolls sociala kanaler och i marknadsföringskampanjer, men då endast i Iskolls kontext.</p>   

            <h2>Ändrade villkor</h2>
            <p>Iskoll förbehåller sig rätten att ändra dessa Villkor. Ändringar meddelas i Iskolls app. Genom att fortsätta använda Iskolls app accepterar du de nya villkoren. Om du inte accepterar de nya villkoren kan du alltid avinstallera Iskolls app.</p>

            <h2>Cookies</h2>
            <p>Cookies är textfiler som sparas automatiskt på din enhet och kan innehålla information om hur du använder Iskolls app. Cookies applicerar bara till webbversionen av Iskolls app. Du hittar mer info om cookies på www.aboutcookies.org or www.allaboutcookies.org. Där beskrivs också hur du avvaktiverar och rensar cookies, dock kan Iskolls app fungera sämre när cookies är avaktiverade.</p>

        </div>
    </Layout>
  )
}