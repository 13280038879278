import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { FaEnvelope } from 'react-icons/fa';
import { FaFacebookSquare } from 'react-icons/fa';
import { FaYoutubeSquare } from 'react-icons/fa';

import "../styles/footer.scss"
export default function Footer() {
  return (
    <div className="footer">
        <div className="icon-bg">
            <StaticImage className="icon" layout="fixed" width={80}  src={"../images/icon.png"} formats={["auto", "webp", "avif"]}/>
        </div>
        <div className="info-text">
            <p><a href="https://www.iskoll.se">www.iskoll.se</a></p>
            <div className="email-info"><FaEnvelope/> <p>info at iskoll.se</p></div>
            <div className="social-info">
            <a href="https://www.facebook.com/iskoll.se" target="_blank" rel="noreferrer"><FaFacebookSquare className="fb"/></a>
            <a href="https://www.youtube.com/channel/UC9wGziqwX5qybDKB9GLvL7w" target="_blank" rel="noreferrer"><FaYoutubeSquare className="yt"/></a>
            </div>
        </div>

    </div>
  )
}